<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
    <c-tab
      dense
      type="tabcard"
      inlineLabel
      align="left"
      :tabItems="tabItems"
      height="530px"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :summary.sync="summary"
          :charmTypeName="tab.name"
          :ramRiskAssessmentPlanIds="ramRiskAssessmentPlanIds"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 're-check-charm-summary-tab',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramTechniqueCd: '',
        }
      },
    },
    assessmentName: {
      type: String,
      default: '',
    },
    ramRiskAssessmentPlanIds: {
      type: Array,
      default: () => [],
    },
    summary: {
      type: Object,
      default: function() {
        return {
          data: [],
        }
      },
    },
  },
  data() {
    return {
      tab: 'estimation',
    };
  },
  computed: {
    tabItems() {
      return [
        { name: 'estimation', icon: 'edit', label: '위험도 추정', component: () => import(`${'./reCheckSummary.vue'}`) },
        { name: 'result', icon: 'camera_enhance', label: '위험도 결정', component: () => import(`${'./reCheckSummary.vue'}`) },
      ]
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // url setting
    },
  }
};
</script>