var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
    },
    [
      _c("c-tab", {
        attrs: {
          dense: "",
          type: "tabcard",
          inlineLabel: "",
          align: "left",
          tabItems: _vm.tabItems,
          height: "530px",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    summary: _vm.summary,
                    charmTypeName: tab.name,
                    ramRiskAssessmentPlanIds: _vm.ramRiskAssessmentPlanIds,
                  },
                  on: {
                    "update:popupParam": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:popup-param": function ($event) {
                      _vm.popupParam = $event
                    },
                    "update:summary": function ($event) {
                      _vm.summary = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }